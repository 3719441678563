import { Story } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('storyRest', storyRest);
function storyRest($http, StoryPatcher) {
    const dress = Story.dress;
    return {
        dress: dress,
        isDirty: isDirty,
        get: get,
        save: save,
        del: del,
        getHistory: getHistory,
        getHistoryBoundaries: getHistoryBoundaries,
        unlinkStories: unlinkStories,
        linkStories: linkStories,
    };
    function storyIdInUrl(arg) {
        if (typeof arg === 'object' && arg.id) {
            return arg.id;
        }
        else if (typeof arg === 'object' && arg.identifier) {
            return arg.identifier;
        }
        else {
            return arg;
        }
    }
    function get(arg, board) {
        const id = storyIdInUrl(arg);
        if (board) {
            return $http
                .get(`/api/boards/${board.location}/stories/${id}`)
                .then((response) => {
                return response.data;
            });
        }
        else {
            // Linked cards still make story reading without
            // having a board context.
            return $http
                .get(`/api/stories/${id}`)
                .then((response) => {
                return response.data;
            });
        }
    }
    function getHistory(arg) {
        const id = storyIdInUrl(arg);
        return $http
            .get(`/api/stories/${id}/history/`)
            .then((response) => {
            return response.data;
        });
    }
    function getHistoryBoundaries(arg) {
        const id = storyIdInUrl(arg);
        return $http
            .get(`/api/stories/${id}/history/boundaries/`)
            .then((response) => {
            return response.data;
        });
    }
    function save(story, original, board) {
        if (original) {
            return patch(story, original, board);
        }
        else {
            return create(story, board);
        }
    }
    function create(story, board) {
        const url = (board.location === 'all') ? '/api/stories/' : `/api/boards/${board.id}/stories`;
        const tosend = cleanStoryData(angular.extend({}, story.toRaw()), false);
        return $http
            .post(url, tosend)
            .then((res) => {
            return story.clone(res.data);
        });
    }
    function patch(story, original, board) {
        const boardId = (board.location === 'all') ? board.location : board.id;
        const url = `/api/boards/${boardId}/stories/${story.id}`;
        const storyRaw = cleanStoryData(story.toRaw());
        const originalRaw = cleanStoryData(original.toRaw());
        const patch = StoryPatcher.shallow(originalRaw, storyRaw);
        return $http
            .patch(url, patch)
            .then((res) => {
            return story.clone(res.data);
        });
    }
    function del(story, strategy, replaceBy) {
        const url = `/api/stories/${story.id}`;
        const tosend = angular.extend({}, strategy, replaceBy);
        return $http({
            url: url,
            method: 'DELETE',
            data: tosend,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }
    function unlinkStories(data) {
        return $http
            .post('/api/stories/actions/unlink/', data)
            .then((response) => {
            return response.data;
        });
    }
    function isDirty(story, storyData) {
        const patch = StoryPatcher.shallow(story.toRaw(), storyData);
        const keys = Object.keys(patch).filter((k) => {
            return k !== 'linked' && k !== 'attachments';
        });
        return keys.length > 0;
    }
    function cleanStoryData(data, cleanAttachments = true) {
        delete data._links;
        if (cleanAttachments) {
            delete data.attachments;
        }
        delete data.linked;
        delete data.q;
        delete data._q;
        return data;
    }
    function linkStories(data) {
        return $http
            .post('/api/stories/actions/link/', data)
            .then((response) => {
            return response.data;
        });
    }
}
