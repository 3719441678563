const VARIANTS = {
    attachment: 'thumbs/a',
    kanban: 'thumbs/k',
    casino: 'thumbs/k',
    gallery: 'thumbs/g',
    grid: 'thumbs/g',
    list: 'thumbs/l',
    logo: 'thumbs/l',
};
function Thumbnails(url, variant) {
    if (!url) {
        return null;
    }
    variant = VARIANTS[variant] || variant || 'thumbs';
    return url
        .replace(/\/s\//, `/s/${variant}/`)
        .replace(/\?n=.*$/, '');
}
export default Thumbnails;
