import Dimension from '../Dimension';
import ArrayUtils from '../../utils/ArrayUtils';
export default class StoryBinaryLink extends Dimension {
    get category() {
        return 'links';
    }
    get labelInDimensionList() {
        const s = (this.datatypeOptions || {}).childCardsRole;
        return this.label + (s ? ` / ${s}` : '');
    }
    get requiredFlagLabel() {
        return 'The parent card is required';
    }
    get multipleFlagLabel() {
        return 'A child card may have many parents';
    }
    get hideOnChildFlagLabel() {
        return 'Allow linking from the parent only';
    }
    // Todo, would isTargetKind(kind) be deprecated then?
    isTargetKind(kind) {
        if (!this.datatypeOptions || !this.datatypeOptions.kind) {
            return true;
        }
        if (this.datatypeOptions.kind === kind) {
            return true;
        }
        const kinds = ArrayUtils.dress(this.datatypeOptions.kind);
        return kinds.includes(kind);
    }
    canBeEdited() {
        return super.canBeEdited() && !this.datatypeOptions.hideOnChild;
    }
    /**
     * Whether the values can be edited directly (e.g. in the dimension list
     * and/or from the kanban colum headers)
     */
    canValuesBeDeleted() {
        return true;
    }
    canBeUsedForHierarchy() {
        return true;
    }
    /**
      * Overriden to sort by story title, which is used for value labels.
      */
    getValuesSorter() {
        return (a, b) => {
            if (a.id === null) {
                return b.id === null ? 0 : -1;
            }
            else if (b.id === null) {
                return a.id === null ? 0 : 1;
            }
            else {
                const aL = a.label || '';
                const bL = b.label || '';
                return (aL == bL) ? 0 : (aL < bL ? -1 : 1);
            }
        };
    }
    getInputWidgetCode() {
        return 'storyBinaryLink';
    }
}
