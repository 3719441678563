angular
    .module('klaro')
    .directive('boardExportDocumentOptions', boardExportDocumentOptions);
function boardExportDocumentOptions(PrintIt) {
    return {
        restrict: 'EA',
        template: require('@/core/board/exports/boardExportDocumentOptions.html'),
        scope: {
            boardCtrl: '=',
            form: '=',
            close: '=',
            format: '=',
        },
        link: function (scope) {
            const board = scope.boardCtrl.getBoard();
            scope.form.valid = true;
            scope.exportOptions = {
                includeCardDescription: false,
                pageBreaks: false,
                documentStyle: 'table',
                groupBy: board.isMode('kanban') ? board.getDimensionsAt('displayBy')[0] : null,
                shownDimensions: board.getDimensionsAt('cards').map(d => d.code),
            };
            scope.documentStyles = [
                { code: 'list', title: 'Bullet list' },
                { code: 'table', title: 'Table' },
                { code: 'article', title: 'Book' },
            ];
            scope.tableDimensions = board.getDimensions();
            scope.groupDimensions = board.getRelevantDimensionsForAnchor('displayBy');
            scope.clearGroupBy = function () {
                scope.exportOptions.groupBy = null;
            };
            scope.$watch('exportOptions.documentStyle', (val) => {
                scope.exportOptions.includeCardDescription = (val === 'article');
            });
            scope.form.export = function () {
                reloadBoardIfNeeded().then((board) => {
                    const options = scope.exportOptions;
                    if (scope.format === 'pdf') {
                        printPdf(board, options, scope.boardCtrl);
                    }
                    if (scope.format === 'docx') {
                        printDocx(board, options, scope.boardCtrl);
                    }
                    else if (scope.format === 'html') {
                        printHtml(board, options, scope.boardCtrl);
                    }
                });
            };
            function reloadBoardIfNeeded() {
                const board = scope.boardCtrl.getBoard();
                if (scope.exportOptions.includeCardDescription) {
                    return board.withInfoDepth('medium');
                }
                else {
                    return Promise.resolve(board);
                }
            }
            function printPdf(board, options, boardCtrl) {
                PrintIt.downloadAsPdf(board, options, boardCtrl);
            }
            function printDocx(board, options, boardCtrl) {
                PrintIt.downloadAsDocx(board, options, boardCtrl);
            }
            function printHtml(board, options, boardCtrl) {
                PrintIt.downloadAsHtml(board, options, boardCtrl);
            }
        },
    };
}
