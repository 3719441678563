angular
    .module('klaro')
    .service('attachmentsService', attachmentsService);
function attachmentsService(attachmentRest, $timeout) {
    return {
        add: add,
        attachmentDataFor: attachmentDataFor,
        attachmentsDataFor: attachmentsDataFor,
        drop: drop,
        canCover: canCover,
        makeCover: makeCover,
        dropCover: dropCover,
        fileUploadHandler: fileUploadHandler,
    };
    function fileUploadHandler(board, story, promise) {
        return promise
            .then((files) => {
            return add(board, story, files);
        })
            .then(() => {
            $timeout(() => board.reloadStory(story), 1000);
        })
            .catch(board.onError);
    }
    function attachmentDataFor(story, fileInfo, isCover = false) {
        const data = Object.assign({}, fileInfo, {
            description: '',
            isCover: isCover,
        });
        if (story.identifier) {
            data.story = story.identifier;
        }
        return data;
    }
    function attachmentsDataFor(story, files, isCover = false) {
        return files.map((fileInfo, i) => {
            return attachmentDataFor(story, fileInfo, isCover && i === 0);
        });
    }
    function add(board, story, files) {
        const ps = files.map((fileInfo) => {
            const attachmentData = attachmentDataFor(story, fileInfo);
            return attachmentRest
                .addAttachment(attachmentData);
        });
        return Promise.all(ps).then((attachments) => {
            return ensureCoverFromAttachments(board, story, attachments);
        });
    }
    function drop(board, story, attachment) {
        return attachmentRest
            .deleteAttachment(story.id, attachment.id);
    }
    function ensureCoverFromAttachments(board, story, attachments) {
        const attachment = attachments.find(a => a.isImage);
        if (!attachment) {
            return;
        }
        if (!canCover(attachment)) {
            return;
        }
        if (!story.getCoverImageUrl(board.getBoard())) {
            return makeCover(board, story, attachment, true);
        }
    }
    function canCover(attachment) {
        return attachment.isImage;
    }
    function makeCover(board, story, attachment, andSaveBoard = false) {
        return board
            .ensureCoverImageDimension(andSaveBoard)
            .then(() => {
            return attachmentRest
                .setCover(story.id, attachment.id);
        })
            .catch(board.onError);
    }
    function dropCover(board, story) {
        return attachmentRest
            .dropCover(story.id);
    }
}
