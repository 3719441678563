angular
    .module('klaro')
    .service('storyCardActions', storyCardActionsService)
    .controller('StoryCardActionsController', StoryCardActionsController);
function storyCardActionsService($uibModal) {
    function open(board, story, target, showSelectionCheckboxes = true) {
        return $uibModal
            .open({
            template: require('@/core/story/storyCardActions.html'),
            controller: 'StoryCardActionsController',
            controllerAs: 'ctrl',
            bindToController: true,
            windowClass: 'dropwdown-menu story-card-actions',
            size: 'small',
            resolve: {
                board: function () { return board; },
                story: function () { return story; },
                target: function () { return target; },
                showSelectionCheckboxes: function () { return showSelectionCheckboxes; },
            },
        })
            .result
            .then(() => { }, //on close
        () => target.closest('.card-actions').classList.remove('open'));
    }
    return { open: open };
}
function StoryCardActionsController(board, story, target, showSelectionCheckboxes, $rootScope, $scope, $uibModalInstance, $location, $document, $window, events, attachmentsService, navigation, Config, Clipboard, MyPinnedStories) {
    this.$onInit = function () {
        target.closest('.card-actions').classList.add('open');
    };
    $scope.showSelectionCheckboxes = showSelectionCheckboxes;
    const closeOnClick = function (elm) {
        if (elm.target.nodeName === 'LI' && !elm.target.classList.contains('submenu')) {
            $uibModalInstance.dismiss();
        }
    };
    const positionMenu = function () {
        let top, left;
        const menu = angular.element($document.find('.story-card-actions .actions')[0]);
        const menuTriggeredFrom = target.getBoundingClientRect();
        const windowHeight = $window.innerHeight;
        const windowWidth = $window.innerWidth;
        const menuHeight = menu[0].getBoundingClientRect().height;
        const menuWidth = menu[0].getBoundingClientRect().width;
        if (menuTriggeredFrom.top + menuHeight < windowHeight) {
            top = menuTriggeredFrom.top + 29;
        }
        else {
            top = menuTriggeredFrom.top - menuHeight;
        }
        if (menuTriggeredFrom.right + menuWidth < windowWidth) {
            left = menuTriggeredFrom.right;
        }
        else {
            left = menuTriggeredFrom.right - menuWidth;
        }
        menu.css('top', top);
        menu.css('left', left);
        const submenu = menu.find('.dropdown-menu');
        if (submenu.length) {
            const submenuHeight = submenu[0].getBoundingClientRect().height;
            const submenuWidth = submenu[0].getBoundingClientRect().width;
            if (menuTriggeredFrom.right + menuWidth + submenuWidth > windowWidth) {
                menu[0].classList.add('submenu-left');
            }
            if (menuTriggeredFrom.top + menuHeight + submenuHeight > windowHeight) {
                menu[0].classList.add('submenu-top');
            }
        }
    };
    $uibModalInstance
        .rendered
        .then(() => {
        positionMenu();
        events.observeElement($scope, 'click', closeOnClick, $document[0]);
    });
    $scope.canWrite = function () {
        return board.canWrite();
    };
    $scope.openCard = function ($event) {
        board.storyClicked(story, $event);
    };
    $scope.copyCardUrl = function () {
        const cardUrl = `${$location.protocol()}://${$location.host()}/boards/all#${story.urlHash}`;
        Clipboard
            .copy(cardUrl);
    };
    $scope.hasCover = function () {
        return story.coverImage && story.coverImage.url;
    };
    $scope.removeCover = function () {
        return attachmentsService
            .dropCover(board, story)
            .then(() => {
            return board.reloadStory(story)
                .then(s => story = s)
                .catch(navigation.failed);
        })
            .catch(navigation.noop);
    };
    $scope.openBoardSettings = function () {
        board.toggleBoardSettings('cards-content');
    };
    $scope.selectCard = function () {
        $uibModalInstance.dismiss();
        return board.toggleStorySelection(story);
    };
    $scope.duplicateCard = function () {
        const newStory = board.newStory(story.duplicate());
        const options = {
            isNew: true,
            editing: true,
        };
        board
            .openStoryModal(newStory, options)
            .catch(navigation.noop);
    };
    $scope.fileUploaded = function (promise) {
        return promise
            .then((files) => {
            attachmentsService
                .add(board, story, files)
                .then(() => {
                $uibModalInstance.dismiss();
                return board.reloadStory(story)
                    .then(s => story = s)
                    .catch(navigation.failed);
            });
        })
            .catch(navigation.noop);
    };
    $scope.archiveActionLabel = function () {
        const isArchived = story.isArchived(board.getBoard());
        return `${isArchived ? 'Unarchive' : 'Archive'} card`;
    };
    $scope.mayArchive = function () {
        return $rootScope.ksp.cards.mayArchive(board.getBoard());
    };
    $scope.toggleArchiveStory = function () {
        board
            .toggleArchiveStory(story)
            .catch(navigation.failed);
    };
    $scope.pinActionLabel = function () {
        return MyPinnedStories.getActionLabel(story);
    };
    $scope.togglePinStory = function () {
        const pinned = MyPinnedStories.isStoryPinned(story);
        const askForConfirmation = pinned || !$scope.isDashboard();
        MyPinnedStories
            .togglePinStory(story, askForConfirmation)
            .catch(navigation.failed);
    };
    $scope.deleteStory = function () {
        board
            .deleteStory(story)
            .catch(navigation.failed);
    };
    $scope.isDashboard = function () {
        return board.getBoard().isAllCards();
    };
}
