export default (function () {
    const ArrayUtils = {
        dress: dress,
        dup: dup,
        replace: replace,
        replaceAt: replaceAt,
        without: without,
        diff: diff,
        minus: minus,
        union: union,
        deleteAt: deleteAt,
        moveAt: moveAt,
        sorterBy: sorterBy,
        moveInOrdering: moveInOrdering,
        head: head,
        tail: tail,
        isSuperset: isSuperset,
        isSubset: isSubset,
        isSameset: isSameset,
        isSamelist: isSamelist,
        intersects: intersects,
        groupBy: groupBy,
        binarySearch: binarySearch,
        findIndexByObjectPropertyValue: findIndexByObjectPropertyValue,
        uniq: uniq,
        partition: partition,
    };
    /**
     * Dress `arg` as an array, whatever it is.
     *
     * When arg is already an array, returns it. When undefined, returns
     * an empty array. Otherwise return a singleton containing arg.
     */
    function dress(arg) {
        if (arg === undefined) {
            return [];
        }
        return Array.isArray(arg) ? arg : [arg];
    }
    function dup(array, extra) {
        let dup = array.slice();
        if (extra !== undefined) {
            dup = dup.concat(extra);
        }
        return dup;
    }
    function replace(array, matcher, replacement) {
        return array.map((value) => {
            return matcher(value) ? replacement : value;
        });
    }
    function replaceAt(array, at, elm) {
        const d = dup(array);
        d.splice(at, 1, elm);
        return d;
    }
    function deleteAt(array, at) {
        if (at < 0) {
            return array;
        }
        const d = dup(array);
        d.splice(at, 1);
        return d;
    }
    function without(array, value, fn) {
        if (fn === undefined && value !== undefined) {
            const i = array.indexOf(value);
            return deleteAt(array, i);
        }
        else if (fn !== undefined) {
            const copy = [];
            array.forEach((x) => {
                if (!fn(x)) {
                    copy.push(x);
                }
            });
            return copy;
        }
        else {
            return array;
        }
    }
    function minus(left, right) {
        return left.filter(x => right.indexOf(x) === -1);
    }
    function diff(left, right) {
        return left.filter(x => right.indexOf(x) === -1);
    }
    function union(left, right) {
        return left.concat(diff(right, left));
    }
    /*
     * Returns an array where element at `index` has been moved at index `at`.
     */
    function moveAt(arr, index, at) {
        if (index < 0 || index >= arr.length) {
            throw new Error(`Invalid index \`${index}\``);
        }
        if (at < 0) {
            at = 0;
        }
        if (at >= arr.length) {
            at = arr.length - 1;
        }
        arr = dup(arr);
        arr.splice(at, 0, arr.splice(index, 1)[0]);
        return arr;
    }
    function sorterBy(by) {
        // utility functions
        const default_cmp = (a, b) => {
            let result;
            if (a === b) {
                result = 0;
            }
            else if ((a === undefined || a === null) && (b !== undefined && b !== null)) {
                result = 1;
            }
            else if (b === undefined || b === null) {
                result = -1;
            }
            else {
                result = a < b ? -1 : 1;
            }
            return result;
        };
        const getCmpFunc = (primer, reverse) => {
            const dfc = default_cmp; // closer in scope
            let cmp = default_cmp;
            if (primer) {
                cmp = function (a, b) {
                    return dfc(primer(a), primer(b));
                };
            }
            if (reverse) {
                return function (a, b) {
                    return -1 * cmp(a, b);
                };
            }
            return cmp;
        };
        const fields = [];
        const n_fields = by.length;
        let field, name, cmp;
        // preprocess sorting options
        for (let i = 0; i < n_fields; i++) {
            field = by[i];
            if (typeof field === 'string') {
                name = field;
                cmp = default_cmp;
            }
            else if (typeof field === 'function') {
                name = null;
                cmp = field;
            }
            else {
                name = field.name;
                cmp = getCmpFunc(field.primer, field.reverse);
            }
            fields.push({
                name: name,
                cmp: cmp,
            });
        }
        // final comparison function
        return (A, B) => {
            let name, result;
            for (let i = 0; i < n_fields; i++) {
                result = 0;
                field = fields[i];
                name = field.name;
                if (name) {
                    result = field.cmp(A[name], B[name]);
                }
                else {
                    result = field.cmp(A, B);
                }
                if (result !== 0) {
                    break;
                }
            }
            return result;
        };
    }
    /**
     * Moves `item` within `collection`, by changing its `ordering`
     * attribute, in such a way that it ends up being at `at` index
     * when the collection is ordered according to the ordering.
     *
     * The collection MUST have object items having an `ordering`
     * attribute which is an integer. `item` MUST be an element
     * of the collection. `at` SHOULD be between 0 and the collection
     * size.
     *
     * The collection and item are NOT directly modified by the method.
     * Instead, it computes the new `ordering` attribute for `item` and
     * passes them (item, ordering) to the `callback` function. The
     * latter MUST return a Promise that resolves to the modified item
     * (making backend calls if necessary).
     *
     * The method itself returns a Promise that resolves to a new
     * collection, that includes the modified item, and ordered
     * according to the `ordering` field of the items.
     */
    function moveInOrdering(collection, item, at, callback) {
        const sorter = function (d, e) {
            return d.ordering - e.ordering;
        };
        collection = collection.slice().sort(sorter);
        const index = collection.indexOf(item);
        let ordering;
        if (index === at) {
            ordering = collection[at].ordering;
        }
        else {
            let min = 0;
            let max = 10000000;
            if (at <= 0) {
                min = 0;
                max = collection[0].ordering;
            }
            else if (at >= collection.length) {
                min = collection[collection.length - 1].ordering;
                max = 100000 + min;
            }
            else {
                min = collection[at - 1].ordering;
                max = collection[at].ordering;
            }
            ordering = Math.round(min + (max - min) / 2);
        }
        const thenFn = (i) => {
            collection.splice(index, 1, i);
            return collection.sort(sorter);
        };
        if (callback) {
            return callback(item, ordering).then(thenFn);
        }
        else {
            return thenFn(Object.assign({}, item, { ordering: ordering }));
        }
    }
    function head(collection) {
        if (!collection || collection.length === 0) {
            return undefined;
        }
        return collection[0];
    }
    function tail(collection) {
        if (!collection) {
            return [];
        }
        return collection.slice(1);
    }
    function isSuperset(xs, ys) {
        return ys.every((y) => xs.indexOf(y) !== -1);
    }
    function isSubset(xs, ys) {
        return xs.every((x) => ys.indexOf(x) !== -1);
    }
    function isSameset(xs, ys) {
        return xs.length === ys.length && isSuperset(xs, ys) && isSubset(xs, ys);
    }
    function isSamelist(xs, ys) {
        if (xs.length !== ys.length) {
            return false;
        }
        for (let i = 0; i < xs.length; i++) {
            if (xs[i] !== ys[i]) {
                return false;
            }
        }
        return true;
    }
    function intersects(xs, ys) {
        if (xs.length === 0 || ys.length === 0) {
            throw 'Arrays cannot be empty';
        }
        return xs.some((x) => ys.indexOf(x) >= 0);
    }
    /**
     * This method returns a "multidimensional" Object made out of a "flat" Array of objects.
     * i.e. from this Array
     *   myFlatArray = [
     *     { key1: 'aaa', key2: 'ddd' },
     *     { key1: 'bbb', key2: 'eee' },
     *     { key1: 'aaa', key2: 'fff' },
     *   ]
     *
     * it returns this Object
     *   myMultidimensionalObject = {
     *     'aaa': [
     *       { key1: 'aaa', key2: 'ddd' },
     *       { key1: 'aaa', key2: 'fff' },
     *     ],
     *     'bbb': [
     *       { key1: 'bbb', key2: 'eee' },
     *     ],
     *   }
     *
     */
    function groupBy(flatArray, groupKey) {
        return flatArray.reduce((acc, row) => {
            const key = row[groupKey];
            acc[key] = acc[key] || [];
            acc[key].push(row);
            return acc;
        }, {});
    }
    function binarySearch(arr, comparator, start, end) {
        if (start === undefined) {
            start = 0;
            end = arr.length - 1;
        }
        if (start > end) {
            return undefined;
        }
        const mid = Math.floor((start + end) / 2);
        const compare = comparator(arr[mid]);
        if (compare === 0) {
            return mid;
        }
        else if (compare > 0) {
            return binarySearch(arr, comparator, start, mid - 1);
        }
        else {
            return binarySearch(arr, comparator, mid + 1, end);
        }
    }
    function findIndexByObjectPropertyValue(arr, property, value) {
        return arr.findIndex(arrayItem => Object.prototype.hasOwnProperty.call(arrayItem, property) && arrayItem[property] === value);
    }
    /**
     * Returns the array passed as parameter without duplicated values.
     * Only works with strings and numbers, not objects
     *
     * @param {Array} arr
     * @returns {Array}
     */
    function uniq(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }
    function partition(array, condition) {
        const yes = [];
        const no = [];
        array.forEach((x) => {
            if (condition(x)) {
                yes.push(x);
            }
            else {
                no.push(x);
            }
        });
        return [yes, no];
    }
    return ArrayUtils;
})();
