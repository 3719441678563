angular
    .module('klaro')
    .service('storyCreateModal', storyCreateModal)
    .controller('StoryCreateModalController', StoryCreateModalController);
function storyCreateModal(klaroModal) {
    return {
        open: function (boardCtrl, story, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storyCreateModal.html'),
                controller: 'StoryCreateModalController',
                windowClass: 'klaro-modal story-create-modal',
                size: 'small full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    story: function () {
                        return story;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StoryCreateModalController($scope, $uibModalInstance, boardCtrl, story, options, robust) {
    options = (typeof options === 'function') ? { onSubmit: options } : options;
    $scope.validDimensionsValues = options.validDimensionsValues;
    $scope.storyData = story.toRaw();
    $scope.initialData = angular.extend({}, $scope.storyData);
    $scope.dimensions = boardCtrl.getDimensions();
    $scope.saving = false;
    $scope.$watch('dimensions', (ds) => {
        if (!ds) {
            return;
        }
        const requiredDimensions = ds.filter((d) => {
            return d.canBeEditedAsCardDimension() && boardCtrl.isRequired(d);
        });
        // to be defined
        $scope.tbdDimensions = requiredDimensions.filter((d) => {
            return $scope.initialData[d.code] === undefined;
        });
        // ambiguous dimensions
        $scope.ambDimensions = $scope.dimensions.filter((d) => {
            return d.canBeEditedAsCardDimension() &&
                !boardCtrl.isRequired(d) &&
                boardCtrl.hasFilter(d) &&
                $scope.initialData[d.code] === undefined;
        });
        // to be review
        $scope.tbrDimensions = ds.filter((d) => {
            return d.canBeEditedAsCardDimension()
                && $scope.initialData[d.code] !== undefined
                && $scope.isRelevant(d)
                && d.canBeEdited();
        });
        $scope.tbrDimensionsLegend = `Dimension${$scope.tbrDimensions.length > 1 ? 's' : ''}
                                already prefilled
                                <span class="badge purple">${$scope.tbrDimensions.length}</span>`;
    });
    $scope.tbrDimensionsOpen = !!options.openPrefilledSection;
    $scope.anyRelevant = function () {
        return $scope.tbdDimensions.find((d) => $scope.isRelevant(d));
    };
    $scope.anyAmbiguous = function () {
        return $scope.ambDimensions.find((d) => $scope.isRelevant(d));
    };
    $scope.onValueCreated = function (dimension, value) {
        boardCtrl.addDimensionValue(dimension, value).then(() => {
            $scope.dimensions = boardCtrl.getDimensions();
        });
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.canSave = function () {
        return $scope.formIsValid() && !$scope.saving;
    };
    $scope.formIsValid = function () {
        return $scope.storyCreateForm.$valid
            && $scope.storyData.title
            && $scope.storyData.title.trim() !== '';
    };
    $scope.isRelevant = function (dim) {
        return dim.isRelevant($scope.storyData || {}, boardCtrl);
    };
    $scope.openDetails = function () {
        const story = boardCtrl.newStory($scope.storyData);
        const options = {
            isNew: true,
            editing: true,
        };
        boardCtrl.openStoryModal(story, options)
            .then($uibModalInstance.close)
            .catch($scope.cancel);
    };
    $scope.createCard = function () {
        if (!$scope.canSave()) {
            return;
        }
        $scope.saving = true;
        options
            .onSubmit(boardCtrl.newStory($scope.storyData))
            .then($uibModalInstance.close)
            .finally(() => {
            $scope.saving = false;
        })
            .catch(onError);
    };
    const kindDimension = boardCtrl.getKindDimension();
    $scope.$watch(`storyData.${kindDimension.code}`, () => {
        $scope.cardPlaceholder = boardCtrl.getPlaceholderFor($scope.storyData);
    });
    function onError(err) {
        $scope.error = robust.message(err.data, false);
    }
}
