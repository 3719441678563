angular
    .module('klaro')
    .directive('boardModes', boardModes);
function boardModes() {
    return {
        restrict: 'EA',
        template: require('@/core/board/modes.html'),
        require: '^board',
        scope: {},
        link: function (scope, elm, attrs, boardCtrl) {
            scope.setMode = boardCtrl.setMode.bind(boardCtrl);
            scope.isMode = boardCtrl.isMode.bind(boardCtrl);
            scope.getMode = boardCtrl.getMode.bind(boardCtrl);
            function computeUIState() {
                scope.allViews = boardCtrl.getBoard().getDisplayModes().map((m) => {
                    return Object.assign({}, m, { cssClass: buttonClasses(m) });
                });
                scope.visibleViews = getVisibleViews(scope.allViews);
            }
            boardCtrl.$watch('board', computeUIState, scope);
            function getVisibleViews(allViews) {
                const currentMode = scope.getMode();
                const candidates = allViews.filter((v, i) => i < 3 || v.code === currentMode);
                if (candidates.length > 3) {
                    candidates.splice(2, 1);
                }
                return candidates;
            }
            function buttonClasses(view) {
                const classes = [
                    'icon-lg',
                    `icon-View-${view.label}`,
                ];
                if (view.code === scope.getMode()) {
                    classes.push('selected');
                }
                return classes.concat(' ');
            }
        },
    };
}
