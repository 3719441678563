angular
    .module('klaro')
    .directive('summariesDropdown', summariesDropdown);
function summariesDropdown(dimensionEditModal, dimensionRest, navigation) {
    return {
        restrict: 'E',
        require: '^board',
        template: require('@/core/story/summariesDropdown.html'),
        link: function (scope, elm, attrs, board) {
            scope.closeDropdownMenu = function () {
                angular.element(elm).closest('details')[0].removeAttribute('open');
            };
            scope.newNumericDimension = function () {
                dimensionEditModal.open(dimensionRest.newOne('Integer'))
                    .result
                    .then((dimension) => {
                    board.addNewDimension(dimension).then(() => {
                        scope.dimensions = board.getDimensions();
                    });
                })
                    .catch(navigation.noop);
            };
            scope.summarizableDimensions = function () {
                return board.getSummarizableDimensions();
            };
            scope.hasSummary = function (summary, dimension) {
                return board.hasSummary(summary, dimension);
            };
            scope.hasSummaryUsing = function (dimension) {
                return board.hasSummaryUsing(dimension);
            };
            scope.setSummary = function (summary, dimension) {
                board.setSummary(summary, dimension).then(scope.closeDropdownMenu);
            };
        },
    };
}
