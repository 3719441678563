import { setChartTypeCode } from '@klaro/corejs/state';
import { ChartViewVariants } from '@klaro/corejs/state';
import angular from 'angular';
angular
    .module('klaro')
    .directive('chartTypeSelect', chartTypeSelect);
function chartTypeSelect() {
    return {
        restrict: 'EA',
        template: require('@/core/board/chartTypeSelect.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            scope.options = Object.entries(ChartViewVariants).map(([code, cv]) => {
                return { code, label: cv.label };
            });
            scope.selected = board.getBoard().chartView.getTypeCode();
            scope.$watch('selected', (selected) => {
                const boardState = board.getBoard();
                board.applyStateAction((s) => setChartTypeCode(boardState, selected));
            });
        },
    };
}
