angular
    .module('klaro')
    .directive('unlockedProject', unlockedProject);
function unlockedProject(navigation, confirm, lockProjectModal) {
    return {
        restrict: 'E',
        scope: {
            projectTemplate: '=',
        },
        template: require('@/onboard/unlockedProject.html'),
        link: function (scope, elm, attrs) {
            scope.templateName = scope.projectTemplate.name;
            scope.templateUrl = navigation.getTemplateWebsiteUrl(scope.projectTemplate);
            scope.change = function () {
                return confirm({
                    h2: 'Danger zone!',
                    headerClass: 'modal-header-error',
                    yesClass: 'btn-danger',
                    hint: 'Changing the template will remove ALL data from your project.',
                    showDone: false,
                    noLabel: 'Cancel',
                    yesLabel: 'Yes, CHANGE...',
                }, () => {
                    navigation
                        .gotoUrl('/onboard/');
                });
            };
            scope.keep = function () {
                lockProjectModal
                    .open()
                    .result
                    .then(navigation.reload)
                    .catch(navigation.noop);
            };
        },
    };
}
