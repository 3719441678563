angular
    .module('klaro')
    .directive('newBoardLink', newBoardLink);
function newBoardLink(newBoardModal, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/board/newBoardLink.html'),
        require: '^board',
        scope: {
            linkLabel: '@',
            fastTrack: '=',
        },
        link: function (scope, elm, attrs, boardCtrl) {
            scope.isDirty = function () {
                return boardCtrl && boardCtrl.boardIsDirty();
            };
            scope.isDashboard = function () {
                return boardCtrl.getBoard().isAllCards();
            };
            scope.newBoard = function () {
                const modal = newBoardModal.open({
                    title: 'New board',
                    fastTrack: scope.fastTrack,
                    mode: boardCtrl.getMode(),
                    name: boardCtrl.getBoardLabel(),
                    dimensions: boardCtrl.getDimensions(),
                    isDashboard: scope.isDashboard(),
                });
                modal
                    .result
                    .then(newBoardWorkflow)
                    .catch(navigation.noop);
            };
            async function newBoardWorkflow(data) {
                if (scope.fastTrack) {
                    createBoard(data)
                        .then(gotoBoard)
                        .catch(navigation.failed);
                }
                else {
                    const boardModel = boardCtrl.getBoard().board;
                    const newBoardBase = (data.startFrom === 'scratch') ? boardModel.neutralSkeleton() : boardModel;
                    let newBoardData = newBoardBase
                        .withLabel(data.label)
                        .withObjective(data.objective)
                        .withLocation(data.location)
                        .withMode(data.mode);
                    if (data.anchors) {
                        newBoardData = newBoardData.withAnchors(data.anchors);
                    }
                    const newBoard = await boardCtrl.getBoard().with({ board: newBoardData });
                    boardCtrl.toggleBoardSettings('filters', {
                        asNewBoard: true,
                        newBoard,
                    });
                }
            }
            function gotoBoard(board) {
                navigation.gotoBoard(board);
            }
            function createBoard(data) {
                return boardCtrl && boardCtrl.cloneBoard(data);
            }
        },
    };
}
