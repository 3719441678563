angular
    .module('klaro')
    .directive('newDimensionLink', newDimensionLink);
function newDimensionLink($rootScope, dimensionRest, dimensionEditModal, navigation) {
    return {
        restrict: 'EA',
        template: require('@/core/dimension/newDimensionLink.html'),
        replace: true,
        require: '?board',
        scope: {
            onClose: '&',
            category: '=',
            aboveBoard: '@',
        },
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.newDimension = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                openDimensionModal(dimensionRest.newOne());
            };
            function openDimensionModal(d) {
                const installers = (i) => {
                    return !scope.category || i.group === scope.category;
                };
                dimensionEditModal.open(d, null, installers)
                    .result
                    .then(onNewDimension)
                    .catch(navigation.noop);
            }
            function onNewDimension(dimension) {
                if (boardCtrl) {
                    return boardCtrl
                        .addNewDimension(dimension)
                        .then(() => close(dimension));
                }
                else {
                    close(dimension);
                }
            }
            function close(dimension) {
                scope.onClose({ dimension: dimension });
            }
        },
    };
}
