import { ProjectMember } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('newMemberLink', newMemberLink);
function newMemberLink(editMemberModal, navigation) {
    return {
        scope: {
            onSubmit: '&',
        },
        restrict: 'EA',
        template: require('@/core/member/newMemberLink.html'),
        link: function (scope, elm, attrs) {
            scope.newUser = function () {
                const member = ProjectMember.dress({});
                editMemberModal.open(member, {
                    isNew: true,
                    onSubmit: function (member) {
                        scope.onSubmit({ member: member });
                    },
                }).result.catch(navigation.noop);
            };
        },
    };
}
