import { DataObject, Markdowner, ArrayUtils, Thumbnails } from '../utils';
import LinkedStories from './LinkedStories';
import Board from './Board';
import Stories from './Board/Stories';
import Attachment from './Attachment';
import Dsl from '../print/Dsl';
import FileUtils from '../utils/FileUtils';
import { Title } from './Dimensions';
class Story extends DataObject {
    static dress(raw, world) {
        if (!world) {
            throw new Error('A world is required on stories');
        }
        if (raw.constructor && raw.constructor === this) {
            return raw;
        }
        return new Story(raw, world);
    }
    clone(override) {
        return new Story(Object.assign({}, this.__raw, override), this.__world);
    }
    /** Alias for hasValue.
      * Needed because a Story instance can be used
      * as context in Dimension.withoutDeprecatedValues(context).
      */
    isUsingValueOfDimension(value, dimension, globalContext) {
        return this.hasValue(dimension, value, globalContext);
    }
    /** Returns whether this story has `value` along the given dimension. */
    hasValue(dimension, value, globalContext) {
        return dimension.storyValueMatches(this, value, globalContext);
    }
    hasTitle() {
        const title = this.getTitle();
        return title && title.trim() !== '';
    }
    getTitle() {
        return this[this.titleDimension];
    }
    get titleDimension() {
        const dim = this.__world?.dimensions?.find(d => d instanceof Title);
        return dim?.code || 'title';
    }
    get rawTitle() {
        const value = this[this.titleDimension]?.toString();
        return ArrayUtils.head((value || '').split(/\n/));
    }
    get toptitle() {
        return this._cached('__toptitle', () => {
            return Markdowner.light.render(this.rawTitle).trim();
        });
    }
    get rawSummary() {
        const value = this[this.titleDimension]?.toString();
        return ArrayUtils.tail((value || '').split(/\n/)).join('\n');
    }
    get summary() {
        return this._cached('__summary', () => {
            return Markdowner.light.render(this.rawSummary).trim();
        });
    }
    getCoverImageUrl(board) {
        const dimension = board.getCoverImageDimension();
        if (!dimension) {
            return null;
        }
        const coverImage = dimension.getStoryRawValue(this);
        if (!coverImage) {
            return null;
        }
        const url = coverImage.url;
        if (!url) {
            return null;
        }
        return Thumbnails(url, board.mode);
    }
    isArchived(board) {
        const dimension = board.getArchivedDimension();
        if (!dimension) {
            return false;
        }
        return !!dimension.getStoryRawValue(this);
    }
    getAttachments() {
        return (this.attachments || []).map(Attachment.dress);
    }
    get urlHash() {
        return this.identifier + (!this.title ? '' : (`-${this.title
            .toLowerCase()
            .trim()
            .split('\n')[0]
            .replace(/[\W_]+/g, '-')
            .replace(/[-]+$/g, '')
            .replace(/^[-]+/g, '')}`));
    }
    specToHtml() {
        if (!this.specification) {
            return undefined;
        }
        return Markdowner.full.render(this.specification);
    }
    toFileName(extension) {
        return FileUtils.toFileName(this.title, extension);
    }
    toHtml(options = {}) {
        const linkedAs = options.linkedStoriesAs || 'table';
        return new Dsl(options)
            .print({
            as: 'html5',
            yield: (dsl) => dsl.print({
                as: 'article',
                yield: (dsl) => dsl.linkedStories({
                    yield: (dsl) => dsl.print({
                        as: linkedAs,
                    }),
                }),
            }),
        })
            .toHtml([this]);
    }
    hasLinkedStories() {
        return this.linked && this.linked.length > 0;
    }
    linkedStoriesCount() {
        return this.linked ? this.linked.length : 0;
    }
    linkedStories(dims) {
        if (!dims) {
            dims = this.__world.dimensions;
        }
        if (!dims) {
            return;
        }
        const board = Board.dress({
            dimensions: dims,
            filters: {},
        });
        return new LinkedStories({
            parent: this,
            children: Stories.dress(this.linked || [], {
                board: board,
            }),
            board: board,
        });
    }
    /** Returns the original story data without some dimensions */
    duplicate() {
        return Object.keys(this.__raw).reduce((result, key) => {
            if (!['id', 'identifier', 'linked', 'attachments'].includes(key)) {
                result[key] = this.__raw[key];
            }
            return result;
        }, {});
    }
    /**
     * Calls computer and saves the result in a local cache under 'attribute'
     * Next time, the computed result will be returned.
     */
    _cached(attribute, computer) {
        if (this._cachedCache === undefined) {
            this._cachedCache = {};
        }
        if (this._cachedCache[attribute] === undefined) {
            this._cachedCache[attribute] = computer(this);
        }
        return this._cachedCache[attribute];
    }
    get _links() {
        return {
            self: `/stories/${this.identifier}`,
            edit: `/stories/${this.identifier}/edit`,
        };
    }
}
export default Story;
