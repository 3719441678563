angular
    .module('klaro')
    .directive('dimensionInputWidget', dimensionInputWidget);
function dimensionInputWidget($compile, navigation, $window) {
    return {
        restrict: 'E',
        scope: {
            storyData: '=',
            board: '=',
            dimension: '=',
            dimensions: '=',
            isNew: '=',
            canWrite: '=',
            isRequired: '=',
            threeState: '=',
            onValueCreated: '&',
            validValues: '=',
        },
        replace: true,
        link: function (scope, elm) {
            elm.addClass('dimension-input-widget');
            const templateCode = scope.dimension.getInputWidgetCode();
            const template = require(`./dimensionInputWidget/${templateCode}.html`);
            elm.html(template);
            $compile(elm.contents())(scope);
            scope.openParentStory = function (story, $event) {
                $event.stopPropagation();
                $event.preventDefault();
                scope.board
                    .openStoryModal(story)
                    .catch(navigation.noop);
            };
            scope.openParentStories = function (stories, $event) {
                $event.stopPropagation();
                $event.preventDefault();
                for (const story of stories) {
                    $window.open(navigation.urlInProject(`stories/${story}`), story);
                }
            };
        },
    };
}
