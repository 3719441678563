import { Sync, BoardState } from '@klaro/corejs/state';
import { Filters } from '@klaro/corejs/model';
import { AngularRestHandler } from '@klaro/corejs/rest';
const { withMode, BoardSyncModes } = Sync;
angular
    .module('klaro.v1')
    .config(['$routeProvider', '$locationProvider', 'singleModalPage', 'failedModalPage',
    function ($routeProvider, $locationProvider, singleModalPage, failedModalPage) {
        function dimensionsResolver(dimensionRest) {
            return dimensionRest.list();
        }
        function boardResolver(which, mode, callback) {
            return ['$route', '$location', '$http', 'auth', 'klaroUi', 'MyPinnedStories',
                function ($route, $location, $http, auth, klaroUi, MyPinnedStories) {
                    try {
                        const boardId = which ?? ($route.current.params.id ?? 'all');
                        const url = `/api/boards/${boardId}`;
                        const search = $location.search();
                        const canPrefetch = (callback === undefined && Object.keys(search).length === 0);
                        const handler = AngularRestHandler($http);
                        return BoardState.load(url, handler, canPrefetch, (board) => {
                            if (!board.accessibleInThisWorkspace(auth.getViewAs().code)) {
                                const workspaces = board.getWorkspaceCodes();
                                const suitableWorkspace = auth.findSuitableWorkspace(workspaces);
                                if (suitableWorkspace) {
                                    auth.setViewAs(suitableWorkspace);
                                }
                                else {
                                    auth.gotoHome();
                                }
                            }
                            const handlers = [];
                            // First dress some filters for the URL's search.
                            // A special extractor is used, because null value ids
                            // are encoded as "null" strings in URLs. So to properly
                            // compare them with value id's, we need to extract the
                            // later as "null"...
                            const searchX = function (x) {
                                return x.id === null ? 'null' : x.id;
                            };
                            const filters = Filters.dress(search, {
                                extractor: searchX,
                                dimensions: board.dimensions,
                            }, true);
                            // 1. apply filters from url
                            if (!filters.isEmpty()) {
                                handlers.push((b) => {
                                    // Now take the board filters and merge our search filters.
                                    // The board filters extractor will be used at the end, given
                                    // `merge` implementation...
                                    const merged = b.filters.merge(filters);
                                    // Apply those merged filters now.
                                    return b.setFilters(merged);
                                });
                            }
                            // 2. apply search from url
                            if (search._q) {
                                handlers.push((b) => b.withSearch(search._q));
                            }
                            // 3. apply pageSize from url
                            if (search._pageSize) {
                                handlers.push((b) => b.withPageSize(parseInt(search._pageSize)));
                            }
                            if (callback) {
                                handlers.push(callback);
                            }
                            // 3. always hide decks on mobile
                            if (klaroUi.isMobile()) {
                                handlers.push((b) => b.withDecksState(false));
                            }
                            // 4. apply auth for permission
                            handlers.push((b) => {
                                return b.usedBy(auth.getUser(), auth.getViewAs());
                            });
                            // 5. auto sync board as of user preference or per url param
                            handlers.push(async (b) => {
                                const syncMode = search._presenterMode ? 'realTime' : auth.getUser().syncMode;
                                const mode = BoardSyncModes[syncMode];
                                return mode ? withMode(b, mode) : b;
                            });
                            const p = handlers.reduce((boardPromise, handler) => {
                                return boardPromise.then((aBoard) => handler(aBoard));
                            }, Promise.resolve(board));
                            return p.then(b => {
                                MyPinnedStories.fetch(b, false);
                                return b;
                            });
                        }, mode);
                    }
                    catch (ex) {
                        // eslint-disable-next-line no-console
                        console.log('Board loading failed', ex);
                        throw (ex);
                    }
                },
            ];
        }
        function membersResolver(memberRest) {
            return memberRest.list();
        }
        function workspacesResolver(workspaceRest) {
            return workspaceRest.listFull();
        }
        function projectResolver(Project) {
            return Project.get();
        }
        function projectCategoriesResolver(projectRest) {
            return projectRest.categories();
        }
        function templatesResolver(templateRest) {
            return templateRest.list();
        }
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
        });
        $routeProvider
            .when('/', {
            isPublic: true,
            template: '',
            controller: 'HomeController',
        })
            .when('/auth/login', singleModalPage({
            template: require('@/auth/loginModal.html'),
            controller: 'LoginModalController',
            resolve: {
                login: ['$routeParams', function ($routeParams) { return $routeParams.login; }],
            },
        }, { showRecentNews: true }))
            .when('/auth/password/forgotten', singleModalPage({
            template: require('@/auth/password/forgottenModal.html'),
            controller: 'PasswordForgottenModalController',
            resolve: {
                email: ['$routeParams', function ($routeParams) { return $routeParams.email; }],
            },
        }))
            .when('/auth/password/reset/:token', singleModalPage({
            template: require('@/auth/password/resetModal.html'),
            controller: 'PasswordResetModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
                obligation: function () { return undefined; }, // on intent
            },
        }))
            .when('/auth/password/change/:obligation/:token', singleModalPage({
            template: require('@/auth/password/resetModal.html'),
            controller: 'PasswordResetModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
                obligation: ['$routeParams', function ($routeParams) { return $routeParams.obligation; }],
            },
        }))
            .when('/auth/join/:token', singleModalPage({
            template: require('@/auth/joinModal.html'),
            controller: 'JoinModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
            },
        }))
            .when('/auth/validate/:token', singleModalPage({
            template: require('@/auth/validateEmailModal.html'),
            controller: 'ValidateEmailModalController',
        }))
            .when('/auth/email/change/validate/:token', singleModalPage({
            template: require('@/auth/validateEmailChangeModal.html'),
            controller: 'ValidateEmailChangeController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
            },
        }))
            .when('/auth/remember-email-confirmation/', singleModalPage({
            template: require('@/auth/rememberEmailValidationModal.html'),
            controller: 'RememberEmailValidationModalController',
        }))
            .when('/auth/no-board-access', failedModalPage({
            title: 'No board access',
            description: 'It looks like you don’t have access to any board in this\
            project. This may occur when your default workspace has no board. Please\
            check your preferences below.\n\nIf the problem persists, contact the\
            project owner and make sure your workspace has at least one board.',
            report: false,
            close: false,
            home: 'Retry',
            logout: false,
            preferences: true,
        }))
            .when('/auth/forbidden', failedModalPage({
            title: 'Access forbidden',
            description: 'It looks like you don’t have access to the requested\
            page. Please contact your project owner.',
            report: false,
            close: false,
            back: 'Retry',
            logout: true,
            login: true,
            home: 'Go home',
        }))
            .when('/not-found', failedModalPage({
            title: 'Page not found',
            description: 'It looks like the requested page does not exist.\
            Please check, try again, or contact your project owner.',
            report: false,
            close: false,
            home: 'Go home',
            logout: true,
        }))
            .when('/stories/:id/', {
            template: require('@/views/stories/view.html'),
            controller: 'ViewStoryController',
            isPublic: true,
            resolve: {
                board: boardResolver('all', 'single-story'),
            },
        })
            .when('/boards/:id', {
            template: require('@/views/boards/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            isPublic: true,
            resolve: {
                project: projectResolver,
                board: boardResolver(null, 'full'),
            },
        })
            .when('/dashboard', {
            template: require('@/views/dashboard/index.html'),
            controller: 'DashboardController',
            reloadOnSearch: false,
            isPublic: false,
            resolve: {
                project: projectResolver,
                board: boardResolver('all', 'dashboard', (bs) => {
                    const b = bs.board.forDashboard('created');
                    return bs.with({ board: b });
                }),
            },
        })
            .when('/settings/dimensions/', {
            bodyClass: 'settings',
            template: require('@/views/dimensions/list.html'),
            controller: 'DimensionsListController',
            reloadOnSearch: false,
            resolve: {
                dimensions: dimensionsResolver,
            },
        })
            .when('/settings/members/', {
            bodyClass: 'settings',
            template: require('@/views/members/list.html'),
            controller: 'MembersListController',
            resolve: {
                members: membersResolver,
            },
        })
            .when('/settings/workspaces/', {
            bodyClass: 'settings',
            template: require('@/views/workspaces/list.html'),
            controller: 'WorkspacesListController',
            resolve: {
                workspaces: workspacesResolver,
                dimensions: dimensionsResolver,
            },
        })
            .when('/settings/project/', {
            bodyClass: 'settings',
            template: require('@/views/project/info.html'),
            controller: 'ProjectInfoController',
            resolve: {
                project: projectResolver,
            },
        })
            .when('/settings/documentation/', {
            bodyClass: 'settings',
            template: require('@/views/documentation/index.html'),
            controller: 'ProjectDocController',
            resolve: {
                project: projectResolver,
                projectCategories: projectCategoriesResolver,
            },
        })
            .when('/new', singleModalPage({
            template: require('@/onboard/newProjectModal.html'),
            controller: 'NewProjectModalController',
            size: 'sm',
            windowClass: 'klaro-modal new-project-modal',
        }))
            .when('/onboard/', {
            isPublic: false,
            template: require('@/onboard/onboard.html'),
            controller: 'OnboardController',
            resolve: {
                templates: templatesResolver,
                projectCategories: projectCategoriesResolver,
                project: projectResolver,
            },
        })
            .when('/error/', {
            template: '<h1>Error</h1>',
            controller: ['failedModal', function (failedModal) {
                    failedModal.open({
                        title: 'Wnoops',
                        description: 'This is an error message',
                    });
                }],
        })
            .otherwise({
            redirectTo: '/',
        });
    },
]);
