import { MemberList } from '@klaro/corejs/views';
angular
    .module('klaro')
    .controller('MembersListController', MembersListController);
function MembersListController($scope, $rootScope, members, editMemberModal, memberRest, Title, transferOwnershipModal, navigation, auth, $location, events) {
    Title.setCurrentPage('Manage members');
    $rootScope.view = 'members';
    $scope.search = {
        text: ($location.search() || {}).q,
    };
    function reload() {
        memberRest.list().then((allMembers) => {
            members = allMembers;
            refresh();
        });
    }
    $scope.reload = reload;
    function refresh() {
        $scope.list = new MemberList({
            searchText: $scope.search.text,
            members: members,
        });
    }
    $scope.$watchGroup(['members', 'search.text'], refresh);
    $scope.toggleSectionCollapse = function (section) {
        $scope.list = $scope.list.withSectionCollapseToggled(section);
    };
    $scope.editMember = function (member) {
        editMemberModal.open(member, {
            isNew: false,
            onSubmit: $scope.refresh,
        }).result.then(reload).catch(navigation.noop);
    };
    $scope.transferOwnership = function () {
        if (!auth.isOwner()) {
            return;
        }
        transferOwnershipModal
            .open(auth.getUser(), {
            members: members,
            onSubmit: $scope.$parent.refresh,
        })
            .result
            .then(navigation.reload)
            .catch(navigation.noop);
    };
    events.listen($scope, 'klaro.viewAs.changed', reload);
}
