angular
    .module('klaro')
    .service('storyModal', storyModal)
    .controller('StoryModalController', StoryModalController);
function storyModal(klaroModal) {
    return {
        open: function (board, story, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storyModal.html'),
                controller: 'StoryModalController',
                windowClass: 'klaro-modal klaro-overlay story-modal with-nav',
                size: 'large',
                resolve: {
                    board: function () {
                        return board;
                    },
                    story: function () {
                        return story;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StoryModalController($rootScope, $scope, $uibModalInstance, story, storyRest, board, options, confirm, navigation, StoryAutosaver, Clipboard, $location, $timeout, PrintIt, MyPinnedStories, $document) {
    options = (typeof options === 'function') ? { onSubmit: options } : options;
    $scope.inBoard = board;
    $scope.story = story;
    $scope.storyData = story.toRaw();
    $scope.dimensions = board.getDimensions();
    $scope.storyColor = board.getStoryColor(story);
    $scope.editing = options.editing || false;
    $scope.editingDescription = false;
    $scope.scrolled = false;
    $scope.storyAutosaver = StoryAutosaver.build($scope);
    $scope.saving = false;
    function ensureHash() {
        if (story.identifier) {
            navigation.setHash(story.urlHash);
        }
    }
    ensureHash();
    $scope.togglePreview = function () {
        $scope.editingDescription = !$scope.editingDescription;
    };
    $scope.onScrollChanged = function (scrolled) {
        $scope.scrolled = scrolled;
    };
    $scope.reloadStory = function () {
        return board.reloadStory(story)
            .then(s => $scope.story = s)
            .catch(navigation.failed);
    };
    $scope.close = function (saved) {
        $uibModalInstance.close(saved || $scope.story);
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss($scope.story);
    };
    $scope.couldSave = function () {
        return options.canWrite && (isNew() || isDirty());
    };
    $scope.canSave = function () {
        return $scope.couldSave() && $scope.formIsValid() && !$scope.saving;
    };
    $scope.canDelete = function () {
        return $scope.canWrite;
    };
    $scope.mayArchive = function () {
        return $rootScope.ksp.cards.mayArchive($scope.inBoard.getBoard());
    };
    $scope.formIsValid = function () {
        return $scope.storyForm.$valid
            && $scope.storyData.title
            && $scope.storyData.title.trim() !== '';
    };
    // Prevent browser's dialog for saving
    const keyDownHandler = function (event) {
        if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
            if (!$scope.canSave()) {
                event.preventDefault();
            }
        }
    };
    $document.on('keydown', keyDownHandler);
    $scope.$on('$destroy', () => {
        $document.off('keydown', keyDownHandler);
    });
    $scope.save = function (andClose) {
        if (!$scope.canSave()) {
            return;
        }
        $scope.saving = true;
        const newOne = $scope.inBoard.newStory($scope.storyData);
        const oldOne = $scope.story;
        options
            .onSubmit(newOne, isNew() ? undefined : oldOne)
            .then((saved) => {
            if (andClose) {
                $scope.close(saved);
            }
            else {
                story = saved;
                $scope.story = story;
                $scope.storyData = story.toRaw();
                $scope.storyColor = board.getStoryColor(story);
                $scope.storyForm.$setPristine();
                options.isNew = false;
                ensureHash();
            }
            $scope.storyAutosaver.saved();
        })
            .finally(() => {
            $scope.saving = false;
        })
            .catch((err) => {
            $scope.storyAutosaver.failed(err);
        });
    };
    $scope.toggleArchiveStory = function () {
        $scope.inBoard
            .toggleArchiveStory($scope.story)
            .then(() => {
            $scope.reloadStory();
        })
            .catch(navigation.failed);
    };
    function setArchiveActionLabel() {
        const isArchived = $scope.story.isArchived(board.getBoard());
        $scope.archiveActionLabel = `${isArchived ? 'Unarchive' : 'Archive'} card`;
    }
    $scope.$watch('story', function () {
        setArchiveActionLabel();
        setPinActionLabel();
    });
    // Pinning
    $scope.togglePinStory = function () {
        MyPinnedStories
            .togglePinStory($scope.story)
            .then(setPinActionLabel)
            .catch(navigation.failed);
    };
    function setPinActionLabel() {
        $scope.pinActionLabel = MyPinnedStories.getActionLabel($scope.story);
    }
    $scope.deleteStory = function () {
        $scope.inBoard
            .deleteStory($scope.story)
            .then((res) => {
            // When NOT confirming the deletion, `res` = false;
            // When confirming the deletion, `res` is the board with patch applied;
            if (res) {
                return $uibModalInstance.close();
            }
        });
    };
    $scope.$on('modal.closing', (event, reason, closed) => {
        $scope.storyAutosaver.reset();
        if (!closed && $scope.canWrite && isDirty()) {
            event.preventDefault();
            confirm({
                h2: 'Are you sure?',
                hint: 'Unsaved changes will be lost.',
            }, () => {
                $uibModalInstance.close();
            }, () => {
                $scope.storyAutosaver.reset();
            });
        }
    });
    function isNew() {
        return options.isNew;
    }
    $scope.isNew = isNew;
    $scope.canWrite = options.canWrite;
    function isDirty() {
        return storyRest.isDirty(story, $scope.storyData);
    }
    // Handling of on the fly value creation
    $scope.onDimensionCreated = function (dimension) {
        board.addNewDimension(dimension).then(() => {
            $scope.dimensions = board.getDimensions();
        });
    };
    $scope.onValueCreated = function (dimension, value) {
        board.addDimensionValue(dimension, value).then(() => {
            $scope.dimensions = board.getDimensions();
        });
    };
    // Exports
    const defaultOptions = { includeCardDescription: true };
    $scope.exportPdf = function () {
        PrintIt.downloadAsPdf($scope.story, defaultOptions, $scope.inBoard.getGlobalContext());
    };
    $scope.exportDocx = function () {
        PrintIt.downloadAsDocx($scope.story, defaultOptions, $scope.inBoard.getGlobalContext());
    };
    $scope.exportHtml = function () {
        PrintIt.downloadAsHtml($scope.story, defaultOptions, $scope.inBoard.getGlobalContext());
    };
    $scope.copyCardUrl = function ($event) {
        Clipboard
            .copy($location.absUrl())
            .then(() => {
            $event.target.classList.add('copied');
            $timeout(() => {
                $event.target.classList.remove('copied');
            }, 2000);
        });
    };
    $scope.duplicateStory = function () {
        $uibModalInstance.close();
        const story = $scope.inBoard.newStory($scope.story.duplicate());
        const options = {
            isNew: true,
            editing: true,
        };
        board
            .openStoryModal(story, options)
            .catch(navigation.noop);
    };
}
