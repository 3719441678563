angular
    .module('klaro')
    .directive('memberList', memberList);
function memberList(auth, editMemberModal, inviteMemberModal, navigation, confirm, workspaceRest, memberRest, ArrayUtils) {
    return {
        scope: {
            members: '=',
            deleted: '=?',
            onClick: '&',
            title: '@',
        },
        restrict: 'E',
        template: require('@/core/member/memberList.html'),
        link: function (scope) {
            scope.ordering = 'nickname';
            scope.reverse = false;
            scope.orderBy = function (by) {
                if (scope.ordering === by) {
                    scope.reverse = !scope.reverse;
                }
                else {
                    scope.ordering = by;
                    scope.reverse = false;
                }
            };
            scope.isCurrentUser = function (member) {
                const current = auth.getUser();
                const user = member.user;
                return current && user && current.id === user.id;
            };
            scope.editWorkspaces = function (member, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                editMemberModal.open(member)
                    .result
                    .then((workspace) => {
                    if (workspace) { // When the modal is closed by a click on "X", `workspace` is null
                        const clone = member.clone({ workspaces: workspace });
                        updateMember(clone);
                    }
                })
                    .catch(() => { });
            };
            scope.dropFromWorkspace = function (workspace, member, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                const name = member.user ? member.user.firstname : member.nickname;
                confirm(`This will remove *${name}* from the *${workspace.name}* workspace.`, () => {
                    workspaceRest
                        .dropMember(workspace, member)
                        .then(() => {
                        const clone = member.clone({ workspaces: ArrayUtils.without(member.workspaces, workspace, (x) => x.id === workspace.id) });
                        updateMember(clone);
                    })
                        .catch(navigation.failed);
                });
            };
            scope.canBeDeleted = function (member) {
                return member.deletedAt === null && (!member.user || !member.user.isOwner);
            };
            scope.deleteMember = function ($event, member) {
                $event.preventDefault();
                $event.stopPropagation();
                confirm('Are you sure you want to remove this member?', () => {
                    memberRest
                        .remove(member)
                        .then(navigation.reload)
                        .catch(navigation.failed);
                });
            };
            scope.canBeRestored = function (member) {
                return member.deletedAt !== null;
            };
            scope.restoreMember = function ($event, member) {
                $event.preventDefault();
                $event.stopPropagation();
                confirm('Are you sure you want to restore this member?', () => {
                    memberRest
                        .restore(member)
                        .then(navigation.reload)
                        .catch(navigation.failed);
                });
            };
            scope.inviteMember = function ($event, member) {
                $event.preventDefault();
                $event.stopPropagation();
                inviteMemberModal
                    .open(member, {
                    onSubmit: scope.$parent.refresh,
                })
                    .result
                    .catch(navigation.noop);
            };
            function updateMember(clone) {
                scope.members = ArrayUtils.replace(scope.members, (m) => {
                    return m.nickname === clone.nickname;
                }, clone);
            }
        },
    };
}
