angular
    .module('klaro')
    .service('klaroModal', klaroModal);
function klaroModal($uibModal, hotkeys) {
    return {
        open: function (...args) {
            hotkeys.stack();
            const modal = $uibModal.open(...args);
            modal.result.finally(() => {
                hotkeys.pop();
            });
            return modal;
        },
    };
}
