angular
    .module('klaro')
    .service('boardExportServices', boardExportServices);
function boardExportServices(boardRest, boardShareModal, navigation) {
    function downloadAsFile(job, jobResult) {
        const a = document.createElement('a');
        a.href = jobResult.url;
        a.target = '_blank';
        a.download = job.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    function handleJobResult(job, jobResult, shareFooter) {
        if (job.mode == 'download') {
            downloadAsFile(job, jobResult);
        }
        else {
            boardShareModal.open(jobResult.url, shareFooter).result.catch(navigation.noop);
        }
    }
    function jobResultHandler(job, shareFooter) {
        return function (result) {
            return handleJobResult(job, result.data, shareFooter);
        };
    }
    function exportBoard(board, exportData, shareFooter) {
        return boardRest
            .exportBoard(board, exportData)
            .then(jobResultHandler(exportData, shareFooter));
    }
    return {
        exportBoard: exportBoard,
    };
}
