angular
    .module('klaro')
    .controller('ViewStoryController', ViewStoryController);
function ViewStoryController($scope, $rootScope, $routeParams, board, Title, hotkeys, storyRest, StoryAutosaver, navigation) {
    $rootScope.view = 'story';
    $scope.storyData = {};
    $scope.board = board;
    $scope.dimensions = board.dimensions;
    $scope.canWrite = board.canWrite();
    $scope.editing = false;
    $scope.editingDescription = false;
    $scope.storyAutosaver = StoryAutosaver.build($scope);
    $scope.scrolled = false;
    $scope.reloadStory = function () {
        return $scope.boardCtrl.loadStoryDetails($scope.story, false)
            .then((s) => {
            $scope.story = s;
            $scope.boardCtrl.storyChanged(s);
        })
            .catch(navigation.failed);
    };
    $scope.canSave = function () {
        return $scope.canWrite && isDirty();
    };
    $scope.save = function () {
        if (!$scope.canWrite) {
            return;
        }
        const toSave = $scope.boardCtrl.newStory($scope.storyData);
        $scope
            .boardCtrl
            .saveStory(toSave, $scope.story)
            .then((story) => {
            $scope.story = story;
            $scope.storyAutosaver.saved();
        })
            .catch((err) => {
            $scope.storyAutosaver.failed(err);
        });
    };
    $scope.formIsValid = function () {
        return $scope.storyForm.$valid
            && $scope.storyData.title
            && $scope.storyData.title.trim() != '';
    };
    function isDirty() {
        return $scope.story && storyRest.isDirty($scope.story, $scope.storyData);
    }
    $scope.togglePreview = function () {
        $scope.editingDescription = !$scope.editingDescription;
    };
    $scope.onScrollChanged = function (scrolled) {
        $scope.scrolled = scrolled;
    };
    $scope.onValueCreated = function (dimension, value) {
        $scope.boardCtrl.addDimensionValue(dimension, value).then((b) => {
            $scope.dimensions = $scope.boardCtrl.getDimensions();
        });
    };
    $scope.onDimensionCreated = function (dimension) {
        $scope.boardCtrl.addNewDimension(dimension).then(() => {
            $scope.dimensions = $scope.board.dimensions;
        });
    };
    $scope.$watch('boardCtrl', (boardCtrl) => {
        if (!boardCtrl || $scope.story) {
            return;
        }
        boardCtrl
            .loadStoryDetails({ id: $routeParams.id }, false) // not from board, that might yield a 404
            .then((s) => {
            $scope.story = s;
            $scope.storyAutosaver.reset();
        });
    });
    $scope.$watch('story', (story) => {
        if (!story) {
            return;
        }
        Title.setCurrentPage(`Story #${story.identifier}`);
        $scope.storyData = story.toRaw();
        $scope.storyColor = $scope.boardCtrl.getStoryColor(story);
        $scope.storyAutosaver.reset();
    });
    hotkeys.bindTo($scope)
        .add({
        combo: 'mod+s',
        description: 'Save card',
        callback: function (event) {
            event.preventDefault(); // prevents the browser default behavior
            return $scope.save();
        },
    });
}
