angular
    .module('klaro')
    .directive('latestActivity', latestActivity);
function latestActivity(navigation) {
    return {
        template: require('@/core/dashboard/latestActivity.html'),
        scope: true,
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.tabs = [
                {
                    id: 'created',
                    title: 'Recently created',
                },
                {
                    id: 'updated',
                    title: 'Last updated',
                },
                {
                    id: 'search',
                    title: 'Search results',
                },
            ];
            scope.selectedTab = {
                id: 'created',
            };
            function setContent() {
                scope.cards = boardCtrl.getBoard().stories.all;
            }
            scope.$watch('board', () => {
                setContent();
            });
            scope.$watch('selectedTab.id', () => {
                const tab = scope.tabs.find(t => t.id === scope.selectedTab.id);
                if (!tab) {
                    return;
                }
                boardCtrl.mutate(b => b.forDashboard(tab.id));
            });
            scope.visibleTabs = () => {
                if (boardCtrl.getSearch()) {
                    return scope.tabs.filter(t => t.id === 'search');
                }
                else {
                    return scope.tabs.filter(t => t.id !== 'search');
                }
            };
            scope.isActive = (tab) => {
                return tab.id === scope.selectedTab.id;
            };
            scope.storyClicked = (story, $event) => {
                boardCtrl.storyClicked(story, $event);
            };
            scope.seeAll = function () {
                navigation.gotoBoard(boardCtrl.getBoard(), {
                    _q: boardCtrl.getSearch(),
                });
            };
        },
    };
}
