angular
    .module('klaro')
    .service('boardWorkspaceNewModal', boardWorkspaceNewModal)
    .controller('BoardWorkspaceNewModalController', BoardWorkspaceNewModalController);
function boardWorkspaceNewModal(klaroModal) {
    return {
        open: function (boardCtrl, workspace) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardWorkspaceNewModal.html'),
                controller: 'BoardWorkspaceNewModalController',
                windowClass: 'klaro-modal board-workspace-new-modal',
                size: 'small',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    workspace: function () {
                        return workspace;
                    },
                },
            });
        },
    };
}
function BoardWorkspaceNewModalController($scope, $uibModalInstance, boardCtrl, workspaceRest, robust, workspace) {
    let originalLevel;
    $scope.boardName = boardCtrl.getBoardLabel();
    const board = boardCtrl.getBoard();
    $scope.userLevels = board.userLevels();
    if (workspace) {
        $scope.newWorkspace = false;
        $scope.title = $scope.newWorkspaceName = workspace.name;
        $scope.selectedLevel = board.getLevelDetailsForWorkspace(workspace).code;
        originalLevel = Object.assign(new String($scope.selectedLevel));
        $scope.submitButtonText = 'Change workspace permissions';
    }
    else {
        $scope.newWorkspace = true;
        $scope.title = 'New Workspace';
        $scope.selectedLevel = 'viewer';
        $scope.submitButtonText = 'Create new workspace';
    }
    $scope.isPermissionLevelSelected = function (code) {
        return $scope.selectedLevel == code;
    };
    $scope.setPermissionLevel = function (code) {
        $scope.selectedLevel = code;
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.createWorkspace = function () {
        if (!workspace) {
            const formData = { name: $scope.newWorkspaceName };
            workspaceRest
                .create(formData)
                .then((response) => {
                ensureWorkspacePermission(response.code);
                $uibModalInstance.close({ code: response.code });
            })
                .catch((err) => {
                $scope.error = robust.message(err.data, false);
            });
        }
        else {
            ensureWorkspacePermission(workspace.code);
            $uibModalInstance.close({ code: workspace.code });
        }
    };
    const ensureWorkspacePermission = function (workspaceCode) {
        const w = $scope.userLevels[$scope.selectedLevel];
        const perm = { workspace: workspaceCode, canRead: w.canRead, canWrite: w.canWrite, canManage: w.canManage };
        board
            .ensureWorkspacePermission(perm)
            .then((boardWithNewPerm) => {
            updateBoard(boardWithNewPerm);
        })
            .catch((err) => {
            $scope.error = robust.message(err.data, false);
        });
    };
    const updateBoard = function (boardWithNewPerm) {
        const boardData = angular.copy(boardWithNewPerm.toBoardSaveRaw());
        boardCtrl.saveBoard({ action: 'saveBoard', boardData: boardData });
    };
    $scope.canCreateWorkspace = function () {
        return $scope.newWorkspaceForm.$valid &&
            (($scope.newWorkspace && !$scope.newWorkspaceForm.$pristine) ||
                (!$scope.newWorkspace && $scope.selectedLevel != originalLevel.toString()));
    };
}
