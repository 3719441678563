import { LocalStoragePersistence } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .directive('quickNote', quickNote);
function quickNote(Project, navigation, confirm) {
    return {
        template: require('@/core/dashboard/quickNote.html'),
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            var _a;
            const project = Project.getSync();
            const storage = new LocalStoragePersistence();
            const storageKey = `Klaro/${project.id}/QuickNote`;
            scope.quickNote = storage.get(storageKey, { content: '' });
            (_a = scope.quickNote).content || (_a.content = '');
            scope.$watch('quickNote.content', (newC, oldC) => {
                if (oldC === undefined) {
                    return;
                }
                storage.set(storageKey, scope.quickNote);
            });
            scope.showNewCardLink = function () {
                return scope.quickNote.content.trim().length > 1;
            };
            scope.convertToCard = function () {
                const selectedText = getSel();
                const defaults = {
                    title: (selectedText) ? selectedText : scope.quickNote.content.trim(),
                };
                boardCtrl
                    .openNewStoryModal(defaults)
                    .catch(navigation.noop);
            };
            scope.canConvertToCard = function () {
                return boardCtrl.canWrite() && scope.showNewCardLink();
            };
            scope.clearContent = function () {
                confirm({
                    h2: 'Are your sure?',
                    hint: 'This cannot be cancelled',
                    yesLabel: 'Yes, clear note',
                }, () => {
                    scope.quickNote.content = '';
                });
            };
            function getSel() {
                const txtarea = elm.find('textarea')[0];
                const start = txtarea.selectionStart;
                const finish = txtarea.selectionEnd;
                const sel = txtarea.value.substring(start, finish);
                return sel.trim();
            }
        },
    };
}
